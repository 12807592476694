<template>
    <table class="table table-striped">
        <thead>
        <tr>
            <th>{{ block.headerTextAction }}</th>
            <th>{{ block.headerTextRole }}</th>
            <th v-for="(headerTextCase, key) in block.headerTextCases" :key="key">{{ headerTextCase }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(line, key) in block.list" :key="key">
            <th nowrap>{{ line.name }}</th>
            <td>
                <select-field :data="line" :choices="selectroles"></select-field>
            </td>
            <td v-for="(value, key) in line.values" :key="key">
                <input-field :data="value" :append="line.append" :prepend="line.prepend"></input-field>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import InputField from './InputField'
import SelectField from './SelectField'

export default {
  props: [
    'block',
    'selectroles'
  ],
  components: {
    SelectField,
    InputField
  }
}
</script>
