<template>
  <div>
    <h1>Result</h1>
    <h2>Overall ROI</h2>
    <p>
      By implementing a digital solution including Digi-Key’s Price and Availability and Ordering APIs,
      we estimate that you can save:
    </p>
    <p class="overall-saving">
      {{ (resultOverall[0] - resultOverall[1]).toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumSignificantDigits: 3}) }} per year
    </p>
    <h2>ROI in detail</h2>
      <p>
      The one year savings is a benefit of automation. An M2M Connection that utilizes Digi-Key’s APIs
      provides exponentially faster quote and order entry and submission that returns real-time
      pricing and availability. The average hourly cost of the workforce multiplied by the estimated
      amount of time it takes the team to enter a quote or order is subtracted from the average hourly
      cost multiplied by the amount of time the M2M solution takes to complete the same process.
      </p>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Process</th>
            <th v-for="(headerTextCase, key) in profile.details.blocks[0].headerTextCases" :key="key">{{ headerTextCase }}</th>
            <th>Saving</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(block, key) in profile.details.blocks" :key="key">
            <template v-if="block.activated">
              <th>{{ block.titleName }}</th>
              <td class="cell-number">{{ resultBlocks[key][0].toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumSignificantDigits: 3}) }}</td>
              <td class="cell-number">{{ resultBlocks[key][1].toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumSignificantDigits: 3}) }}</td>
              <td class="cell-number">{{ (resultBlocks[key][0] - resultBlocks[key][1]).toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumSignificantDigits: 3}) }}</td>
            </template>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th v-for="(result, key) in resultOverall" :key="key" class="cell-number">
              {{ result.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumSignificantDigits: 3}) }}
            </th>
            <th class="cell-number">
              {{ (resultOverall[0] - resultOverall[1]).toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumSignificantDigits: 3}) }}
            </th>
          </tr>
        </tfoot>
    </table>
    <div class="d-md-flex flex-row-reverse">
      <div class="px-2">
        <b-button :to="{ name: 'simulator-wizard'}" variant="success">Return to the Calculator Wizard</b-button>
      </div>
      <div class="px-2">
        <b-button :to="{ name: 'simulator-manual'}">Advanced mode</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from '@/plugins/firestore'
const firestore = firebase.firestore()
const simulationCollection = firestore.collection('simulations')

export default {
  props: ['resultDetails', 'resultBlocks', 'resultOverall', 'profile', 'firstSimulation'],
  created () {
    const createdAt = firebase.firestore.FieldValue.serverTimestamp()
    const fireRoles = {}
    this.profile.roles.list.forEach(element => {
      fireRoles[element.name] = element.value
    })
    const fireParameters = {}
    this.profile.parameters.list.forEach(element => {
      fireParameters[element.name] = element.value
    })
    const fireDetails = {}
    this.profile.details.blocks.forEach(block => {
      if (block.activated) {
        fireDetails[block.titleName] = {}
        block.list.forEach(element => {
          const values = {}
          element.values.forEach((value, index) => {
            values[block.headerTextCases[index]] = value.value
          })
          fireDetails[block.titleName][element.name] = values
        })
      }
    })
    const fireResultBlocks = {}
    this.resultBlocks.forEach((values, indexBlock) => {
      fireResultBlocks[this.profile.details.blocks[indexBlock].titleName] = {}
      values.forEach((value, index) => {
        fireResultBlocks[this.profile.details.blocks[indexBlock].titleName][this.profile.details.blocks[indexBlock].headerTextCases[index]] = value
      })
    })
    const fireResultOverall = {}
    this.resultOverall.forEach((value, index) => {
      fireResultOverall[this.profile.details.blocks[0].headerTextCases[index]] = value
    })
    simulationCollection.add({
      createdAt,
      firstSimulation: this.firstSimulation,
      roles: fireRoles,
      parameter: fireParameters,
      details: fireDetails,
      resultBlocks: fireResultBlocks,
      resultOverall: fireResultOverall,
      language: window.navigator.language,
      userAgent: window.navigator.userAgent,
      hostname: window.location.hostname
    })
    this.$emit('result-finished')
  }
}
</script>

<style scoped>
.cell-number {
    text-align: right;
}
.overall-saving {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}
</style>
