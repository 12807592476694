<template>
    <div class="select-field-role">
        <b-form-select v-model="data.role">
            <option v-for="(choice, key) in choices" :key="key" :value="key">{{ choice.name }}</option>
        </b-form-select>
    </div>
</template>

<script>
export default {
  props: [
    'data',
    'choices'
  ]
}
</script>

<style scoped>
.select-field-role {
    width: 150px;
}
</style>
