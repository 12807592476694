<template>
    <table class="table table-striped">
        <thead>
        <tr>
            <th>{{ data.textHeader }}</th>
            <th>{{ data.textValue }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(line, key) in data.list" :key="key">
            <th nowrap>{{ line.name }}</th>
            <td>
                <input-field :data="line" :append="line.append" :prepend="line.prepend"></input-field>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import InputField from './InputField'

export default {
  props: [
    'data'
  ],
  components: {
    InputField
  }
}
</script>
