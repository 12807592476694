<template>
  <div>
    <h1>Calculate your ROI</h1>
    <p>
      Implementing a machine-to-machine (M2M) connection through an API can deliver positive Return
      on Investment (ROI) in just one year by saving your team time and ultimately, money. The result
      of this calculator shows how much you will save in one year by implementing Digi-Key’s Price and
      Availability and Ordering APIs.
    </p>
    <h2>Labor Costs</h2>
    <p>
      To begin calculating your specific ROI, think of the roles involved in quoting and ordering.
      Each of those have an hourly cost (wages, benefits, etc.) also known as a labor rate. Average the
      hourly cost of purchasing managers, procurement specialists, buyers and whoever else at your
      company is involved in creating and submitting quotes and orders. Slide the selector below to
      choose your Average cost of the workforce as an hourly rate.<br/>
      <small>
        <a href="https://www.accountingtools.com/articles/how-to-calculate-a-labor-rate.html" target="_blank">
          How to calculate a fully burdened labor rate
        </a>
      </small>
    </p>
    <div class="d-md-flex p-2">
      Average cost of the workforce:
      <div class="px-2 pt-1">
        <b-form-input type="range" v-model.number="profile.roles.list[2].value" min="30" max="150" step="5" @change="updateRoles"></b-form-input>
      </div>
      ${{ profile.roles.list[2].value }} per hour
    </div>
    <h2>Quotes and Orders</h2>
    <p>
      Use the sliding selector to enter the total number of quotes you manage per year,
      the average number of lines per quote, the total number of orders per year,
      and the average number of lines per order.
    </p>
    <div class="d-md-flex p-2" v-for="(parameter, key) in profile.parameters.list" :key="key">
      {{ parameter.name }}:
      <div class="px-2 pt-1">
        <b-form-input type="range" v-model.number="parameter.value" :min="parameter.min" :max="parameter.max" :step="parameter.step"></b-form-input>
      </div>
      {{ parameter.value }} {{ parameter.append }}
    </div>
    <div class="d-md-flex flex-row-reverse">
      <div class="p-2">
        <b-button :to="{ name: 'simulator-result'}" variant="success">Get your result</b-button>
      </div>
      <div class="p-2">
        <b-button :to="{ name: 'simulator-manual'}">Advance mode</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'profile'
  ],
  methods: {
    updateRoles () {
      this.profile.roles.list[0].value = this.profile.roles.list[2].value * 1.5
      this.profile.roles.list[1].value = this.profile.roles.list[2].value * 1.25
      this.profile.roles.list[3].value = this.profile.roles.list[2].value * 0.75
      this.profile.roles.list[4].value = this.profile.roles.list[2].value * 1
    }
  }
}
</script>

<style scoped>
input[type=range]::-ms-track {
  width: 155px;
}
input[type=range] {
  border: 0px
}
</style>
