<template>
  <div class="input-field-detail">
    <b-input-group size="sm" :prepend="prepend" :append="append">
      <b-input v-model.number.lazy="data.value"></b-input>
    </b-input-group>
  </div>
</template>

<script>
export default {
  props: [
    'data',
    'prepend',
    'append'
  ]
}
</script>

<style scoped>
.input-field-detail {
    width: 150px;
}
</style>
