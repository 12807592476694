<template>
  <div>
    <router-view
      :profile="profile"
      :result-details="resultDetails"
      :result-blocks="resultBlocks"
      :result-overall="resultOverall"
      :first-simulation="firstSimulation"
      @reset="defaultValues"
      @result-finished="ResultFinished"
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    firstSimulation: true,
    profile: {
      roles: {
        textHeader: 'Role',
        textValue: 'Total cost of workforce',
        list: [
          {
            name: 'Manager/Seller',
            value: 0,
            prepend: '$',
            append: null
          },
          {
            name: 'Purchaser',
            value: 0,
            prepend: '$',
            append: null
          },
          {
            name: 'Procurement',
            value: 0,
            prepend: '$',
            append: null
          },
          {
            name: 'Reception',
            value: 0,
            prepend: '$',
            append: null
          },
          {
            name: 'Finance',
            value: 0,
            prepend: '$',
            append: null
          }
        ]
      },
      parameters: {
        textHeader: 'Parameter',
        textValue: 'Value',
        list: [
          {
            name: 'Number of quotes',
            value: 0,
            prepend: null,
            append: 'quotes',
            min: 50,
            max: 1000,
            step: 50
          },
          {
            name: 'Average lines per quote',
            value: 0,
            prepend: null,
            append: 'lines',
            min: 10,
            max: 200,
            step: 10
          },
          {
            name: 'Number of orders',
            value: 0,
            prepend: null,
            append: 'orders',
            min: 100,
            max: 10000,
            step: 100
          },
          {
            name: 'Average line per order',
            value: 0,
            prepend: null,
            append: 'lines',
            min: 1,
            max: 10,
            step: 1
          }
        ]
      },
      details: {
        blocks: [
          {
            titleName: 'Quotes',
            headerTextCases: ['Manual way', 'Digital way'],
            headerTextAction: 'Action',
            headerTextRole: 'Responsible',
            activated: true,
            list: [
              {
                name: 'Preparing a new quote',
                role: 0,
                prepend: null,
                append: 'min per quote',
                parametersMultipliers: [0],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              },
              {
                name: 'Quote one line',
                role: 0,
                prepend: null,
                append: 'min per line',
                parametersMultipliers: [0, 1],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              },
              {
                name: 'Review the quote',
                role: 0,
                prepend: null,
                append: 'min per quote',
                parametersMultipliers: [0],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              },
              {
                name: 'Quoting the end customer',
                role: 0,
                prepend: null,
                append: 'min per quote',
                parametersMultipliers: [0],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              }
            ]
          },
          {
            titleName: 'Orders',
            headerTextCases: ['Manual way', 'Digital way'],
            headerTextAction: 'Action',
            headerTextRole: 'Responsible',
            activated: true,
            list: [
              {
                name: 'Supplier pricing confirmation',
                role: 0,
                prepend: null,
                append: 'min per line',
                parametersMultipliers: [2, 3],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              },
              {
                name: 'Update the data in the ERP',
                role: 0,
                prepend: null,
                append: 'min per line',
                parametersMultipliers: [2, 3],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              },
              {
                name: 'Create the order',
                role: 0,
                prepend: null,
                append: 'min per order',
                parametersMultipliers: [2],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              },
              {
                name: 'Approve the order',
                role: 0,
                prepend: null,
                append: 'min per order',
                parametersMultipliers: [2],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              },
              {
                name: 'Send the order',
                role: 0,
                prepend: null,
                append: 'min per order',
                parametersMultipliers: [2],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              },
              {
                name: 'File the order',
                role: 0,
                prepend: null,
                append: 'min per order',
                parametersMultipliers: [2],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              }
            ]
          },
          {
            titleName: 'Receive to Cash',
            headerTextCases: ['Manual way', 'Digital way'],
            headerTextAction: 'Action',
            headerTextRole: 'Responsible',
            activated: true,
            list: [
              {
                name: 'Receive the products and push to ERP',
                role: 0,
                prepend: null,
                append: 'min per line',
                parametersMultipliers: [2, 3],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              },
              {
                name: 'Receive the invoice and push to the ERP',
                role: 0,
                prepend: null,
                append: 'min per line',
                parametersMultipliers: [2, 3],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              },
              {
                name: 'Review the invoice',
                role: 0,
                prepend: null,
                append: 'min per line',
                parametersMultipliers: [2, 3],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              },
              {
                name: 'Manage the payment',
                role: 0,
                prepend: null,
                append: 'min per order',
                parametersMultipliers: [2, 3],
                values: [
                  { value: 0 },
                  { value: 0 }
                ]
              }
            ]
          }
        ]
      }
    }
  }),
  methods: {
    defaultValues () {
      this.profile.roles.list[0].value = 75
      this.profile.roles.list[1].value = 60
      this.profile.roles.list[2].value = 50
      this.profile.roles.list[3].value = 35
      this.profile.roles.list[4].value = 60

      this.profile.parameters.list[0].value = 200
      this.profile.parameters.list[1].value = 80
      this.profile.parameters.list[2].value = 2000
      this.profile.parameters.list[3].value = 4

      this.profile.details.blocks[0].list[0].role = 0
      this.profile.details.blocks[0].list[0].values[0].value = 2
      this.profile.details.blocks[0].list[0].values[1].value = 2
      this.profile.details.blocks[0].list[1].role = 1
      this.profile.details.blocks[0].list[1].values[0].value = 2
      this.profile.details.blocks[0].list[1].values[1].value = 0.1
      this.profile.details.blocks[0].list[2].role = 0
      this.profile.details.blocks[0].list[2].values[0].value = 10
      this.profile.details.blocks[0].list[2].values[1].value = 2
      this.profile.details.blocks[0].list[3].role = 0
      this.profile.details.blocks[0].list[3].values[0].value = 10
      this.profile.details.blocks[0].list[3].values[1].value = 10

      this.profile.details.blocks[1].list[0].role = 2
      this.profile.details.blocks[1].list[0].values[0].value = 2
      this.profile.details.blocks[1].list[0].values[1].value = 0.1
      this.profile.details.blocks[1].list[1].role = 2
      this.profile.details.blocks[1].list[1].values[0].value = 0.5
      this.profile.details.blocks[1].list[1].values[1].value = 0.1
      this.profile.details.blocks[1].list[2].role = 2
      this.profile.details.blocks[1].list[2].values[0].value = 2
      this.profile.details.blocks[1].list[2].values[1].value = 2
      this.profile.details.blocks[1].list[3].role = 0
      this.profile.details.blocks[1].list[3].values[0].value = 2
      this.profile.details.blocks[1].list[3].values[1].value = 2
      this.profile.details.blocks[1].list[4].role = 2
      this.profile.details.blocks[1].list[4].values[0].value = 1
      this.profile.details.blocks[1].list[4].values[1].value = 0.1
      this.profile.details.blocks[1].list[5].role = 2
      this.profile.details.blocks[1].list[5].values[0].value = 1
      this.profile.details.blocks[1].list[5].values[1].value = 1

      this.profile.details.blocks[2].list[0].role = 3
      this.profile.details.blocks[2].list[0].values[0].value = 2
      this.profile.details.blocks[2].list[0].values[1].value = 0.5
      this.profile.details.blocks[2].list[1].role = 4
      this.profile.details.blocks[2].list[1].values[0].value = 2
      this.profile.details.blocks[2].list[1].values[1].value = 0
      this.profile.details.blocks[2].list[2].role = 4
      this.profile.details.blocks[2].list[2].values[0].value = 1
      this.profile.details.blocks[2].list[2].values[1].value = 1
      this.profile.details.blocks[2].list[3].role = 4
      this.profile.details.blocks[2].list[3].values[0].value = 1
      this.profile.details.blocks[2].list[3].values[1].value = 1
    },
    ResultFinished () {
      this.firstSimulation = false
    }
  },
  computed: {
    resultDetails () {
      const resultBlocksArray = []
      for (let block = 0; block < this.profile.details.blocks.length; block++) {
        if (this.profile.details.blocks[block].activated) {
          const singleBlockArray = []
          for (let line = 0; line < this.profile.details.blocks[block].list.length; line++) {
            const currentLine = this.profile.details.blocks[block].list[line]
            const roleCost = this.profile.roles.list[currentLine.role].value
            let multiplier = 1
            for (let multiplierReference = 0; multiplierReference < currentLine.parametersMultipliers.length; multiplierReference++) {
              multiplier *= this.profile.parameters.list[currentLine.parametersMultipliers[multiplierReference]].value
            }
            const singleLineArray = []
            for (let i = 0; i < currentLine.values.length; i++) {
              singleLineArray[i] = currentLine.values[i].value * multiplier * roleCost / 60
            }
            singleBlockArray.push(singleLineArray)
          }
          resultBlocksArray.push(singleBlockArray)
        }
      }
      return resultBlocksArray
    },
    resultBlocks () {
      const resultBlockArray = []
      this.resultDetails.forEach(element => {
        resultBlockArray.push(
          element.reduce((a, b) => {
            for (let i = 0; i < a.length; i++) {
              a[i] += b[i]
            }
            return a
          })
        )
      })
      return resultBlockArray
    },
    resultOverall () {
      const result = [0, 0]
      for (let j = 0; j < this.resultBlocks.length; j++) {
        for (let i = 0; i < this.resultBlocks[j].length; i++) {
          result[i] += this.resultBlocks[j][i]
        }
      }
      return result
    }
  },
  created () {
    this.defaultValues()
  }
}
</script>

<style scoped>
.simulator-enter-active {
  animation: bounce-up .8s;
}
.simulator-leave-active {
  animation: bounce-up .25s reverse;
}
@keyframes bounce-up {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
</style>
