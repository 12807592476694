<template>
    <div>
        <h1>Advanced mode</h1>
        <b-row>
            <b-col lg="6">
                <h2>Labor Costs (per hour)</h2>
                <profile-table :data="profile.roles"></profile-table>
            </b-col>
            <b-col lg="6">
                <h2>Quotes and Orders (per year)</h2>
                <profile-table :data="profile.parameters" ></profile-table>
            </b-col>
        </b-row>
        <div v-for="(block, key) in profile.details.blocks" :key="key">
            <template v-if="block.activated">
                <h2>{{ block.titleName }}</h2>
                <div class="table-responsive">
                    <details-table :block="block" :selectroles="profile.roles.list"></details-table>
                </div>
            </template>
        </div>
        <div class="d-md-flex flex-row-reverse">
            <div class="px-2">
                <b-button :to="{ name: 'simulator-result'}" variant="success">Get your result</b-button>
            </div>
            <div class="px-2">
                <b-button variant="danger" @click="$emit('reset')">Restore default values</b-button>
            </div>
        </div>
    </div>
</template>

<script>
import ProfileTable from './components/ProfileTable'
import DetailsTable from './components/DetailsTable'

export default {
  props: [
    'profile'
  ],
  components: {
    ProfileTable,
    DetailsTable
  }
}
</script>

<style scoped>
thead tr th {
    text-align: center;
}
</style>
